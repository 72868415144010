import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledMenuItem = styled.li``;

const StyledLink = styled(Link)`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 1rem;
  text-decoration: none;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
  padding: 20px;
  color: ${({ theme }) => theme.darkJungleGreen()};
  box-shadow: ${({ theme }) => theme.illuminatingEmerald(0.5)} 6px 6px 4px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.illuminatingEmerald()};
  transition: box-shadow 0.3s, background-color 0.5s, color 0.5s;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.mediumTurqoise()};
    box-shadow: ${({ theme }) => theme.englishGreen(0.25)} 10px 10px 4px;
    background-color: ${({ theme }) => theme.englishGreen()};
  }
  &:hover {
    text-decoration: none;
  }
`;

const StyledTitle = styled.h2`
  font-family: 'Bad Script', serif;
  font-size: 2rem;
`;

const StyledDescription = styled.p`
  font-family: 'Cormorant Garamond', serif;
  font-size: 1rem;
`;

export default ({ title, description, link }) => (
  <StyledMenuItem>
    <StyledLink to={link}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledLink>
  </StyledMenuItem>
);
