import React from 'react';
import Global from './../components/Global';
import styled from 'styled-components';
import MenuItem from './../components/MenuItem';

const StyledMenu = styled.nav`
  min-width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 2rem 0;
`;

const StyledMenuList = styled.ol`
  list-style-type: none;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 300px));
  grid-auto-flow: row dense;
  grid-gap: 1rem 1rem;
  justify-content: space-evenly;
  align-items: start;
`;

export default () => (
  <Global>
    <StyledMenu>
      <StyledMenuList>
        <MenuItem
          title={`Chicken and Egg, Bonnie and Clyde; the same story, different side.`}
          description={`On ears or on necks, always coming in pairs, each telling a different side of the same story.`}
          link={'/'}
        />
        <MenuItem title={`Abstract Noun`} description={`Limited, one-off pieces; part history, part mystery.`} link={'/'} />
        <MenuItem
          title={`Tiled say decay`}
          description={`Her extensive perceived may any sincerity extremity. Indeed add rather may pretty see. Old propriety delighted explained perceived otherwise objection saw ten her. Doubt merit sir the right these alone keeps.`}
          link={'/'}
        />
        <MenuItem
          title={`Lose john poor`}
          description={`Full how way even the sigh. Extremely nor furniture fat questions now provision incommode preserved. Our side fail find like now. Discovered travelling for insensible partiality unpleasing impossible she. Sudden up my excuse to suffer ladies though or. Bachelor possible marianne directly confined relation as on he.`}
          link={'/'}
        />
        <MenuItem
          title={`It allowance prevailed enjoyment`}
          description={`Calling observe for who pressed raising his. Can connection instrument astonished unaffected his motionless preference. Announcing say boy precaution unaffected difficulty alteration him. Above be would at so going heard.`}
          link={'/'}
        />
        <MenuItem
          title={`Of on affixed civilly`}
          description={`Assurance advantage belonging happiness departure so of. Now improving and one sincerity intention allowance commanded not. Oh an am frankness be necessary earnestly advantage estimable extensive.`}
          link={'/'}
        />
        <MenuItem
          title={`Improve him`}
          description={`Friendly as stronger speedily by recurred. Son interest wandered sir addition end say. Manners beloved affixed picture men ask. Explain few led parties attacks picture company. On sure fine kept walk am in it. Resolved to in believed desirous unpacked weddings together. Nor off for enjoyed cousins herself. Little our played lively she adieus far sussex.`}
          link={'/'}
        />
        <MenuItem
          title={`On it differed repeated`}
          description={`Cottage out enabled was entered greatly prevent message. No procured unlocked an likewise. Dear but what she been over gay felt body. Six principles advantages and use entreaties decisively. Eat met has dwelling unpacked see whatever followed.`}
          link={'/'}
        />
      </StyledMenuList>
    </StyledMenu>
  </Global>
);
